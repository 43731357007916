export const pages = [
  {
    date: '20.01.2022',
    status: 'draft',
    pageData: {
      name: 'ARAG',
      meta: {
        title: 'IREEN - ARAG',
        description: 'IREEN - ARAG'
      }
    },

    components: [
      {
        type: 'hero',
        data: {
          backgroundColor: '#E7EFF2',
          title: 'Bei Immobilien-Vermietungen ganz entspannt bleiben',
          text: 'Leicht gesagt, wo man doch als Vermieter so manche Überraschung erleben kann. Mit unserem Vermieterrechtsschutz haben wir für Sie ein Leistungspaket geschnürt, mit dem Sie Eventualitäten gelassen entgegensehen.',
          image:
            'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG.png?alt=media&token=e13bd083-5cd4-4dcd-bb36-5c601a329812'
        }
      },
      {
        type: 'widget',
        data: {
          backgroundColor: '#fff',
          title: 'Welcher Schaden kann mir entstehen?',
          widget: 'calculator'
        }
      },
      {
        type: 'text-icon',
        data: {
          backgroundColor: '#F7F7F7',
          title: 'Vorteile im Überblick',
          description:
            'Der Vermieterrechtsschutz ist zum Privat- oder Firmenrechtsschutz abschließbar.',
          items: [
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2Fhand-icon.svg?alt=media&token=72067a7f-b99b-4e7e-969d-5ad82650c260',
              title: 'Meditation',
              text: 'Wir bieten im Vermieterrechtsschutz auch außergerichtliche Konfliktlösung durch Mediation'
            },
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2Fphone-profile-icon.svg?alt=media&token=6ffa3045-9b24-4875-a762-22176f5e15ab',
              title: 'ARAG Inhouse-Beratung',
              text: 'Wann immer Sie juristischen Rat brauchen – telefonieren Sie mit unseren ARAG Inhouse-Juristen'
            },
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2Fshild-icon.svg?alt=media&token=10b972a4-afdb-4e21-8d16-9a5980a0c8ca',
              title: 'Vermieterschutz',
              text: 'Ausfallsversicherung für eventuelle Mietausfallschäden'
            },
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2Fprofile.svg?alt=media&token=15038e5f-7d3c-4db7-ac9c-7e51a8dc1721',
              title: 'Unabhängiger Versicherer',
              text: 'Die ARAG ist seit 1934 in Familienbesitz – und damit ganz und gar unabhängig. '
            },
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2Finternational.svg?alt=media&token=c4c87c82-03c0-46b5-8aee-f16c892feaf0',
              title: 'International erfahrener Versicherer',
              text: 'In den USA sind wir der führende Rechtsdienstleister, in Italien und Spanien Marktführer.'
            }
          ]
        }
      },
      {
        type: 'text-box',
        data: {
          backgroundColor: '#fff',
          title: 'Beispiele aus dem Alltag: So hilft der Vermieterrechtsschutz',
          btn: {
            title: 'Vermieterrechtsschutz abschließen',
            link: 'https://www.arag.at/vermieterrechtsschutz/'
          },
          items: [
            {
              title: 'Mietausfall',
              firstDescription:
                'Sie haben eine Wohnung vermietet und bezahlen die Mieter nun schon den 4. Monat in Folge keinen Mietzins.',
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG-icon.svg?alt=media&token=5b77e8f3-64b6-4f1a-af48-bd99a64cc930',
              secondDescription:
                'Mit Hilfe von ARAG können Sie den Mietzins gerichtlich geltend machen und die Räumung der Wohnung veranlassen.'
            },
            {
              title: 'Beschwerde Instandsetzungspflicht',
              firstDescription:
                'Die Mieter Ihrer Wohnung behaupten Sie kommen Ihrer Instandsetzungspflicht als Vermieter nicht nach und möchten daher den Mietzins mindern.',
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG-icon.svg?alt=media&token=5b77e8f3-64b6-4f1a-af48-bd99a64cc930',
              secondDescription:
                'Sie möchten sich gegen diese Behauptung wehren. ARAG hilft Ihnen dabei die Forderung der Mieter abzuwehren.'
            },
            {
              title: 'Schadenersatz',
              firstDescription:
                'Sie haben ein Haus vermietet und nutzen die Mieter dieses so ab, dass ernste Schäden am Gebäude eintreten.',
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG-icon.svg?alt=media&token=5b77e8f3-64b6-4f1a-af48-bd99a64cc930',
              secondDescription:
                'ARAG unterstützt Sie dabei Ihre Schadenersatzansprüche geltend zu machen.'
            }
          ]
        }
      },
      {
        type: 'banner',
        data: {
          backgroundColor: '#FDD400',
          title: 'Vermieterrecht durchsetzen – ohne finanzielles Risiko',
          text: 'Souverän bei Konflikten mit Mietern bleiben: Unser Vermieterrechtsschutz sichert Sie ab.',
          btn: {
            title: 'Mehr Informationen',
            link: 'https://www.arag.at/vermieterrechtsschutz/'
          }
        }
      },
      {
        type: 'ColoredTextBox',
        data: {
          backgroundColor: '#fff',
          title: 'Vermieterrecht durchsetzen – ohne finanzielles Risiko',
          text: 'Souverän bei Konflikten mit Mietern bleiben: Unser Vermieterrechtsschutz sichert Sie ab.',
          btn1: {
            type: 'pdf',
            title: 'Informationen herunterladen',
            description: 'PDF | 219 kB',
            link: 'https://www.arag.at/medien/pdf/versicherungsbedingungen/abarb20.pdf'
          },
          btn2: {
            title: 'Vermieterrechtsschutz abschließen',
            link: 'https://www.arag.at/vermieterrechtsschutz/'
          },
          items: [
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG-icon.svg?alt=media&token=5b77e8f3-64b6-4f1a-af48-bd99a64cc930',
              text: '<p>Versichert entweder in der Eigenschaft als <strong>Vermieter</strong>, <strong>Verpächter</strong> oder <strong>Eigentümer</strong> des versicherten Objektes, das nicht den Wohn- oder Betriebszwecken des Versicherungsnehmers oder seiner Angehörigen dient, sondern von fremden Personen genutzt wird.</p>'
            },
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG-icon.svg?alt=media&token=5b77e8f3-64b6-4f1a-af48-bd99a64cc930',
              text: '<p>Vor Einleitung des gerichtlichen Verfahrens ebenfalls versichert sind Verfahren vor den <strong>Schlichtungsstellen</strong> der Gemeinden.</p>'
            },
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG-icon.svg?alt=media&token=5b77e8f3-64b6-4f1a-af48-bd99a64cc930',
              text: '<p>Wir übernehmen Kosten für die <strong>außergerichtliche Tätigkeit des Anwaltes</strong> bis zu 1 % der Versicherungssumme, wenn die Angelegenheit außergerichtlich endgültig erledigt werden kann.</p>'
            },
            {
              icon: 'https://firebasestorage.googleapis.com/v0/b/summer-ranger-286213.appspot.com/o/partner-page%2FARAG-icon.svg?alt=media&token=5b77e8f3-64b6-4f1a-af48-bd99a64cc930',
              text: '<p>Kosten für die außergerichtliche Konfliktlösung durch <strong>Mediation</strong> werden ebenfalls übernommen. Grundsätzlich stehen pro Versicherungsfall € 4.500,- an Kosten zur Verfügung. In Fällen der Co-Mediation € 6.500,-.</p>'
            }
          ]
        }
      },
      {
        type: 'banner',
        data: {
          backgroundColor: '#FDD400',
          title: 'Ausfallsversicherung für Mietausfallschäden',
          mobileTitle: 'Ausfalls- versicherung für Mietausfallschäden',
          text: 'Sie haben das Vermiet- und Verpachtungsrisiko im Liegenschafts-Rechtsschutz versichert? - Komplettieren Sie diesen Schutz mit unserer Mietausfallversicherung.',
          btn: {
            title: 'Mehr Informationen',
            link: 'https://www.arag.at/vermieterrechtsschutz/'
          }
        }
      },

      {
        type: 'Text2Column',
        data: {
          backgroundColor: '#fff',
          title:
            'Sie wurden Opfer von Mietnomaden? Die Mieter räumen die Wohnung nicht und bezahlen keine Miete?',
          describe:
            'Für nähere Informationen wenden Sie sich gerne an uns. Informieren Sie sich jetzt unter:',
          number: '01 53102-1600',
          items: [
            {
              text: '<p>Sie erhalten wahlweise <strong>6</strong> oder <strong>12 Monatsmieten</strong> von ARAG ersetzt, wenn der Mieter, obwohl das Mietverhältnis beendet ist, die Wohnung nicht zurück gibt und die Miete nicht bezahlt.</p>'
            },
            {
              text: '<p>ARAG ersetzt auch den Mietausfall, wenn der Mieter das Objekt zwar geräumt hat, aber es so <strong>verwüstet</strong> hinterlassen hat, dass es <strong>renoviert</strong> werden muss. Ersetzt wird der Mietausfall für die Dauer der Renovierung, maximal für 3 Monate.</p>'
            }
          ]
        }
      },
      {
        type: 'divider',
        data: {
          backgroundColor: '#ffffff'
        }
      },
      {
        type: 'TextFullWidth',
        data: {
          backgroundColor: '#fff',
          title: 'Vermeiden Sie unangenehme Überraschungen!',
          text: 'Sprechen Sie die Beraterin und oder den Berater Ihres Vertrauens auf den Vermieterrechtsschutz der ARAG an oder rufen Sie uns zum Ortstarif an oder lassen Sie sich kostenlos zurückrufen. Wir informieren Sie gerne über den ARAG Vermieterrechtsschutz und auch unsere übrigen Produkte.',
          btn: {
            title: 'Mehr Informationen',
            link: 'https://www.arag.at/vermieterrechtsschutz/'
          }
        }
      }
    ]
  }
];
