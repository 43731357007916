import type { MutationTree } from 'vuex';
import AlertTypeEnum from '~/components/toast/toastEnum';

export const state = () => ({
  show: false,
  alertType: AlertTypeEnum.Success,
  message: '',
  timeout: -1
});

export type ToastStore = ReturnType<typeof state>;
export const mutations: MutationTree<ToastStore> = {
  showToast: (state: ToastStore, data) => {
    state.show = true;
    state.alertType = data.alertType;
    state.message = data.message;
    state.timeout = data.timeout;
  },

  closeToast: (state: ToastStore, val: boolean) => {
    state.show = val;
  }
};
