import { Plugin } from '@nuxt/types';
import { initializeAxios } from '~/utils/api';

const accessor: Plugin = ({ $axios, app }) => {
  initializeAxios(
    $axios.create({
      baseURL: app.$config.API_URL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  );
};

export default accessor;
