import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
// import { pages } from './mock/partnerPage';
@Module({
  name: 'partnertPage',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class PartnertPage extends VuexModule {
  pages = [];

  @Mutation
  setData(data: any) {
    this.pages = data;
  }

  @Mutation
  setDataFromApi(data: any) {
    const publishedDate = data.filter(
      (item: any) => item.pageData.status !== 'draft'
    );
    const parsData = publishedDate.map((item: any) => ({
      components: item?.components?.map((component: any) => ({
        data: JSON.parse(component.data),
        type: component.type
      })),
      pageData: {
        name: item.pageData.name,
        meta: JSON.parse(item.pageData?.meta)
      }
    }));
    this.pages = parsData;
  }
}
