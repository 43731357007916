import { MutationTree } from 'vuex';
import dayjs from 'dayjs';
import { GetExpose, Pictures, PublishDetails } from '~/schema/expose.api';
export const state = () => ({
  edit: false as boolean,
  editType: 'title' as string,
  exposeLink: '' as string,
  showAllImages: false as boolean,
  step: 1 as number,
  result: false as boolean,
  title: '' as string,
  offerType: 'sell' as string,
  offerPrice: 0 as number,
  description: '' as string,
  firstName: '' as string,
  createAt: '' as string,
  lastName: '' as string,
  email: '' as string,
  phoneNumber: '' as string | null,
  publishedOn: {
    willhaben: { publishStatus: 'DRAFT' },
    immobilienscout24: { publishStatus: 'DRAFT' }
  } as PublishDetails,
  publishedOnWillHaben: false as Boolean,
  willhabenPublishedDate: '01.05.2022' as string,
  SelectedImage: {} as Object,
  FloorplanImage: [] as Array<any>,
  KitchenImage: [] as Array<any>,
  LivingRoomImage: [] as Array<any>,
  BathroomImage: [] as Array<any>,
  ExteriorViewImage: [] as Array<any>,
  MiscellaneousImage: [] as Array<any>
});

export const ireenDescription =
  '<p><br></p><p><strong>IREEN </strong>hilft bei der Bewertung und Selbstvermarktung von Immobilien, spart Zeit und Geld und macht unabhängig vom Makler!</p><p><br></p><p>Die Bewertung ist bei <strong>IREEN </strong>immer kostenlos und soll Markttransparenz schaffen. </p><p><strong>IREEN </strong>ist nicht der Urheber dieser Anzeige und auch nicht verantwortlich für den Inhalt und die Richtigkeit der Angaben. </p><p><br></p><p>Urheber und in alleiniger Verantwortung ist der Inserent welcher in den Kontaktdaten genannt wird.  </p><p><br></p><p><strong>Also: </strong> besuchen Sie unsere Website über <strong>ireen.com</strong> informieren Sie sich gerne über die einzigartigen Services, Vorteile und Leistungen!</p>';

export type ExposeWizard = ReturnType<typeof state>;

const initialStateCopy = state();

export const mutations: MutationTree<ExposeWizard> = {
  SET_STEP: (state, step: number) => (state.step = step),
  SET_SHOWALLIMAGES: (state, showAllImages: boolean) =>
    (state.showAllImages = showAllImages),
  SET_EDIT_type: (state, editType: string) => (state.editType = editType),
  SET_EDIT: (state, edit: boolean) => (state.edit = edit),
  SET_TITLE: (state, title: string) => (state.title = title),
  SET_OFFERTYPE: (state, offerType: string) => (state.offerType = offerType),
  SET_OFFERPRICE: (state, offerPrice: number) =>
    (state.offerPrice = offerPrice),
  SET_DESCRIPTION: (state, description: string) => {
    const descriptionWithOutIreenDescription = description.replace(
      ireenDescription,
      ''
    ); // remove ireen description that shown on shared expose like wilhaben
    state.description = descriptionWithOutIreenDescription;
  },
  SET_FIRSTNAME: (state, firstName: string) => (state.firstName = firstName),
  SET_LASTNAME: (state, lastName: string) => (state.lastName = lastName),
  SET_EMAIL: (state, email: string) => (state.email = email),
  SET_PHONENUMBER: (state, phoneNumber: string) =>
    (state.phoneNumber = phoneNumber),
  SET_FLOORPLANIMAGE: (state, object: object) =>
    state.FloorplanImage.push(object),
  REMOVE_FLOORPLANIMAGE: (state, url: any) =>
    (state.FloorplanImage = state.FloorplanImage.filter(
      (item) => item.url !== url
    )),
  SET_KITCHEN: (state, object: object) => state.KitchenImage.push(object),
  REMOVE_KITCHEN: (state, url: any) =>
    (state.KitchenImage = state.KitchenImage.filter(
      (item) => item.url !== url
    )),
  SET_RESULT: (state, result: boolean) => (state.result = result),
  SET_LINK: (state, exposeLink: string) => (state.exposeLink = exposeLink),
  SET_LIVINGROOM: (state, object: object) => state.LivingRoomImage.push(object),
  REMOVE_LIVINGROOM: (state, url: any) =>
    (state.LivingRoomImage = state.LivingRoomImage.filter(
      (item) => item.url !== url
    )),

  SET_BATHROOM: (state, object: object) => state.BathroomImage.push(object),
  REMOVE_BATHROOM: (state, url: any) =>
    (state.BathroomImage = state.BathroomImage.filter(
      (item) => item.url !== url
    )),

  SET_EXTERIORVIEW: (state, object: object) =>
    state.ExteriorViewImage.push(object),
  REMOVE_EXTERIORVIEW: (state, url: any) =>
    (state.ExteriorViewImage = state.ExteriorViewImage.filter(
      (item) => item.url !== url
    )),
  SET_MISCELLANEOUS: (state, object: object) =>
    state.MiscellaneousImage.push(object),
  REMOVE_MISCELLANEOUS: (state, url: any) =>
    (state.MiscellaneousImage = state.MiscellaneousImage.filter(
      (item) => item.url !== url
    )),
  SET_SELECTIMAGE: (state, object: object) => (state.SelectedImage = object),
  REMOVE_SELECTIMAGE: (state) => (state.SelectedImage = {}),
  SET_PUBLISHED_ON: (state, val: any) => (state.publishedOn = val),
  SET_PUBLISHED_ON_WILLHABEN_DATE: (state, data: string) => {
    state.publishedOn.willhaben.publishStatus = data;
  },
  SET_PUBLISHED_ON_IMMOBILIENSCOUT24: (state, data: string) => {
    state.publishedOn.immobilienscout24.publishStatus = data;
  },
  REMORE_EXPOSE: (state) => {
    const keys = Object.keys(state) as Array<keyof typeof state>;
    keys.forEach(<K extends keyof typeof state>(key: K) => {
      state[key] = initialStateCopy[key];
    });
    state.KitchenImage = [];
    state.LivingRoomImage = [];
    state.BathroomImage = [];
    state.ExteriorViewImage = [];
    state.MiscellaneousImage = [];
    state.FloorplanImage = [];
    state.publishedOn = {
      willhaben: { publishStatus: 'DRAFT' },
      immobilienscout24: { publishStatus: 'DRAFT' }
    } as PublishDetails;
  },
  SET_EXPOSE: (state, data: GetExpose) => {
    state.result = true;
    state.exposeLink = data.id;
    state.title = data.listing.description.title;
    state.description = data.listing.description.descriptionNote;
    state.offerPrice = data.listing.priceInformation?.primaryPrice || 0;
    state.createAt = dateConvertor(data.meta.createdAt);
    state.publishedOn = data.meta.publishDetails;
    if (data.listing.contact) {
      state.email = data.listing.contact?.emailAddress;
      state.phoneNumber = data.listing.contact?.contactPhone;
      state.firstName = data.listing.contact?.firstName;
      state.lastName = data.listing.contact?.lastName;
    }
    data.listing.pictures && imageMaper(state, data.listing.pictures);
    state.publishedOn = data.meta.publishDetails;
    publishedOnWillHaben(state, data.meta.publishDetails);
  }
};

function imageMaper(state: ExposeWizard, pictures: Array<Pictures>) {
  pictures.forEach((picture) => {
    switch (picture.type) {
      case 'FLOOR_PLAN':
        state.FloorplanImage.push(picture);
        break;
      case 'INTERIOR_KITCHEN':
        state.KitchenImage.push(picture);
        break;
      case 'INTERIOR_LIVINGROOM':
        state.LivingRoomImage.push(picture);
        break;
      case 'INTERIOR_BATHROOM':
        state.BathroomImage.push(picture);
        break;
      case 'EXTERIOR':
        state.BathroomImage.push(picture);
        break;
    }
    if (picture.isTitlePicture) {
      state.SelectedImage = picture;
    }
  });
}
function dateConvertor(timestamp: string) {
  const date = dayjs(timestamp);

  return date.format('DD.MM.YYYY');
}

function publishedOnWillHaben(state: ExposeWizard, published: PublishDetails) {
  if (published.willhaben.publishStatus !== 'DRAFT') {
    state.publishedOnWillHaben = true;
    state.willhabenPublishedDate = dateConvertor(
      published.willhaben?.publishedAt
    );
  }
}
