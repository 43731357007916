
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import VueI18n from 'vue-i18n';
import { Country, selectedCountry } from '~/schema/country';
import MultiMarketStore from '~/store/multiMarket';
import { Store, Service } from '~/decorators';
import { MultiMarket } from '~/services/multiMarket.service';

@Component
export default class MarketDialog extends Vue {
  @Prop() changeLocation!: boolean;
  @Prop() show!: boolean;
  @Prop() getEmail!: boolean;
  loading = false;
  @Store() multiMarketStore!: MultiMarketStore;
  countryType = Country;
  userSelectedCountry = 'AT';
  @Service() multiMarketService!: MultiMarket;

  close() {
    this.$emit('closeMultiMarkt');
    // enable redirectToNewMarket so far becuse we don't have multi market yet
    // if (this.getEmail) {
    //   this.redirectToNewMarket();
    // }
  }

  get countries() {
    return Object.keys(selectedCountry).map(item => ({
      text: this.$t(`multi-market.getMail.${item}`),
      value: selectedCountry[item as keyof typeof selectedCountry]
    }));
  }

  get countryName() {
    return this.multiMarketStore?.selectedCountry;
  }

  set countryName(val: string) {
    this.$store.commit('multiMarket/SET_SELECTEDCOUNTRY', val);
  }

  get country() {
    return this.$store.state.multiMarket?.country || Country.AT;
  }

  get email() {
    return this.multiMarketStore?.email;
  }

  set email(val: string) {
    this.$store.commit('multiMarket/SET_EMAIL', val);
  }

  async submit() {
    this.loading = true;
    const data = this.$store.getters['multiMarket/submitData'];
    try {
      await this.multiMarketService.storeMail(data);
    } catch (e) {}
    this.loading = false;

    this.close();
  }

  redirectToNewMarket() {
    const path = this.$route.fullPath;
    const pathAsArray = path.split('/');
    pathAsArray[1] = this.userSelectedCountry;
    window.location.pathname = pathAsArray.join('/');
  }

  changeMarket(country: Country) {
    this.userSelectedCountry = Country.AT;
    // this.$store.commit('multiMarket/SET_COUNTRY', country);
    this.$store.commit('multiMarket/SET_COUNTRY', Country.AT);
    this.$store.commit('userProfile/SET_SELECTED', true);
    if (country !== Country.AT) {
      this.$emit('submitEmail');
    } else {
      // this.redirectToNewMarket();
      // enable redirectToNewMarket so far becuse we don't have multi market yet
      this.close();
    }
  }

  private _isValidEmail(email: string): boolean {
    return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  get emailRules(): ((v: string) => true | VueI18n.TranslateResult)[] {
    return [
      (v: string) =>
        this._isValidEmail(v) || this.$i18n.t('register.errors.invalidEmail')
    ];
  }
}
