import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Country, sendMail } from '~/schema/country';
@Module({
  stateFactory: true,
  preserveState: true,
  name: 'multiMarket',
  namespaced: true
})
export default class MultiMarket extends VuexModule {
  country!: Country;
  selected: boolean = false;
  email!: string;
  selectedCountry!: string;
  submitEmail: boolean = false;
  get getCountry(): Country {
    return this.country;
  }

  get submitData(): sendMail {
    return { email: this.email, country: this.selectedCountry };
  }

  @Mutation
  SET_SUBMITEMAIL(val: boolean): void {
    this.submitEmail = val;
  }

  @Mutation
  SET_EMAIL(email: string): void {
    this.email = email;
  }

  @Mutation
  SET_SELECTEDCOUNTRY(selectedCountry: string): void {
    this.selectedCountry = selectedCountry;
  }

  @Mutation
  SET_SELECTED(selected: boolean): void {
    this.selected = selected;
  }

  @Mutation
  SET_COUNTRY(country: Country): void {
    this.country = country;
    this.selected = true;
  }
}
