import type { MutationTree } from 'vuex';
import { categories } from './mock/job-categories';

export const state = () => ({
  count: 0,
  categories
});
export type JobCountState = ReturnType<typeof state>;

export const getters = {
  findJobById: (state: JobCountState) => (id: string) => {
    return state.categories.flatMap(c => c.jobs).find(j => j.id === id);
  }
}

export const mutations: MutationTree<JobCountState> = {
  SET_COUNT: (state: any, count: number) => (state.count = count)
};
