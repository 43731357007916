import { inject, singleton } from 'tsyringe';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import RefinedEvaluation from '~/store/refinedEvaluation';
import EvaluationsStore from '~/store/evaluations';

@singleton()
export class RefinedEvaluationService {
  private refinedEvaluationStore: RefinedEvaluation;
  private evaluationsStore: EvaluationsStore;

  constructor(
    @inject('store') private readonly store: Store<any>
  ) {
    this.refinedEvaluationStore = getModule(RefinedEvaluation, store);
    this.evaluationsStore = getModule(EvaluationsStore, store);
  }

  fillRefinedEvaluationStore() {
    if (this.evaluationsStore.hasSelectedEvaluation) {
      const evaluation = this.evaluationsStore.selectedEvaluation!;

      this.refinedEvaluationStore.setRefinedData(evaluation.listing);
    }
  }
}
