export default function ({ route, store, redirect }) {
  if (store.state?.userProfile?.user === null) {
    if (route.path === '/at/dashboard') {
      redirect('/at/loading?from=/at/dashboard')
    }
    if (route.path === '/at/dashboard/objekt-radar') {
      redirect('/at/loading?from=/at/dashboard/objekt-radar')
    }
  }
}
