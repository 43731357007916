import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface WissenData {
  id: number;
  title: string;
  text: string;
  bgColor: string;
  date: string;
  category: string;
  readTime: string;
  autor: string;
}

@Module({
  stateFactory: true,
  preserveState: true,
  name: 'wissen',
  namespaced: true
})
export default class wissen extends VuexModule {
  categories: Array<any> = [];
  selectedCategory: number = 0;
  pages: number = 0;
  allPosts: Array<any> = [];
  users: Array<any> = [];
  realtedArticles: Array<any> = [];
  currentPage: number = 1;
  @Mutation
  SET_SELECTED_CATEGORY(selectedCategory: number) {
    this.selectedCategory = selectedCategory;
  }

  @Mutation
  SET_ALL_POSTS(allPosts: Array<any>) {
    this.allPosts = allPosts;
  }

  @Mutation
  SET_ALL_CATEGORIES(AllCategories: Array<any>) {
    const categories = AllCategories.filter((obj) => obj.count !== 0);
    this.categories = categories;
  }

  @Mutation
  SET_ALL_USERS(users: Array<any>) {
    this.users = users;
  }

  @Mutation
  SET_PAGES(pages: number) {
    this.pages = pages;
  }

  @Mutation
  SET_CUREENT_PAGE(currentPage: number) {
    this.currentPage = currentPage;
  }

  @Mutation
  SET_REALTED_ARTICLES(realtedArticles: Array<any>) {
    this.realtedArticles = realtedArticles;
  }

  @Mutation
  SET_SHOW_MORE_POST(showMorePosts: Array<any>) {
    this.allPosts.push(...showMorePosts);
  }
}
