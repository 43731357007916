export enum PolarOptions {
  No,
  Yes
}

export enum ConditionOptions {
  FIRST_TIME_USE,
  REFURBISHED,
  MINT_CONDITION,
  FULLY_RENOVATED,
  NEED_OF_RENOVATION,
  WELL_KEPT
}

export enum KitchenOptions {
  OPEN_KITCHEN,
  FITTED_KITCHEN,
  KITCHENETTE,
  NO_KITCHEN
}

export enum HeatingTypeOptions {
  CENTRAL_HEATING,
  DISTRICT_HEATING,
  FLOOR_HEATING,
  SELF_CONTAINED_CENTRAL_HEATING,
  STOVE_HEATING
}

export enum FiringTypeOptions {
  AIR_HEAT,
  ALTERNATIVE,
  BLOCK_HEATING,
  COAL,
  DISTRICT_HEATING,
  ELECTRICITY,
  GAS,
  GEOTHERMAL,
  OIL,
  PELLET_HEATING,
  SOLAR_HEATING,
  WATER_ELECTRICITY_HEATING
}

export enum FloorTypeOptions {
  BOARDS,
  CARPET,
  GRANITE,
  LAMINATE,
  LINOLEUM,
  MARBLE,
  PARQUET,
  PLASTIC,
  SCREED,
  STONE,
  TERRACOTTA,
  TILES
}

export enum OutdoorAreasOptions {
  BALCONY,
  LOGGIA,
  TERRACE,
  GARDEN
}

export enum OrientationOptions {
  NORTH,
  NORTH_EAST,
  NORTH_WEST,
  SOUTH,
  SOUTH_EAST,
  SOUTH_WEST,
  EAST,
  WEST
}

export enum ViewOptions {
  AFAR,
  SEE,
  MOUNTAINS
}

export enum ParkingSpaceOptions {
  BASEMENT_GARAGE,
  CARPORT,
  DUPLEX,
  GARAGE,
  PARKING_SPACE,
  PARKING_DECK
}

export interface IRefinedEvaluation {
  estateType?: string;
  estateDetailType?: string;
  yearOfConstruction?: number;
  heatingDemand?: string;
  heatingDemandClass?: string;
  numberOfFloors?: number;
  floor?: number;
  lift?: PolarOptions;
  isOwner?: PolarOptions;
  transactionPriceHistory?: {
    price?: number,
    date?: string,
  }[];
  isRented?: PolarOptions;
  rentPrice?: number;
  rentDate?: string;
  fixedTermLease?: PolarOptions;
  fixedTermLeaseDate?: string;
  heatingCosts?: number;
  heatingTax?: number;
  heatingGross?: boolean;
  operationalCosts?: number;
  operationalTax?: number;
  operationalGross?: boolean;
  electricityCosts?: number;
  electricityTax?: number;
  electricityGross?: boolean;
  additionalCosts?: number;
  additionalTax?: number;
  additionalGross?: boolean;
  cellar?: PolarOptions;
  cellarArea?: number;
  bikeRoom?: PolarOptions;
  storeRoom?: PolarOptions;
  washingDryingRoom?: PolarOptions;
  suitableForSharedApartment?: PolarOptions;
  isVacationProperty?: PolarOptions;
  apartmentOrientation?: OrientationOptions[];
  newBuilding?: PolarOptions;
  firstTimeUse?: PolarOptions;
  condition?: ConditionOptions;
  numberOfBathrooms?: number;
  bathroomBathTube?: PolarOptions;
  bathroomBidet?: PolarOptions;
  bathroomWindow?: PolarOptions;
  bathroomShower?: PolarOptions;
  numberOfToilets?: number;
  guestToilet?: PolarOptions;
  bathroomUrinal?: PolarOptions;
  numberOfBedrooms?: number;
  kitchen?: KitchenOptions;
  heatingType?: HeatingTypeOptions;
  firingType?: FiringTypeOptions;
  floorType?: FloorTypeOptions[];
  sauna?: PolarOptions;
  fireplace?: PolarOptions;
  airConditioning?: PolarOptions;
  furnished?: PolarOptions;
  outdoorAreas?: OutdoorAreasOptions[];
  numberOfBalconies?: number;
  balconyArea?: number | null;
  balconyOrientation?: OrientationOptions[];
  numberOfLoggias?: number;
  loggiaArea?: number | null
  loggiaOrientation?: OrientationOptions[];
  numberOfTerraces?: number;
  terraceArea?: number | null;
  terraceOrientation?: OrientationOptions[];
  gardenArea?: number | null;
  gardenOrientation?: OrientationOptions[];
  view?: ViewOptions[];
  parkingSpace?: ParkingSpaceOptions[];
  numberOfParkingSpaces?: number;
  numberOfBasementGarageSpaces?: number;
  numberOfCarportSpaces?: number;
  numberOfDuplexSpaces?: number;
  numberOfGarageSpaces?: number;
  numberOfParkingDeckSpaces?: number;
  handicappedAccessible?: PolarOptions;
  swimmingPool?: PolarOptions;
  winterGarden?: PolarOptions;
  shutter?: PolarOptions;
}
