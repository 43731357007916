
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { Store } from '~/decorators';
import EvaluationsStore from '~/store/evaluations';
import UserProfile from '~/store/userProfile';
import PropertyTypeSearchStore from '~/store/propertyTypeSearch';

@Component
export default class UserProfileTooltip extends Vue {
  @Store() userProfile!: UserProfile;
  @Store() evaluationsStore!: EvaluationsStore;
  @Prop() user!: any;
  @Prop() lang!: string;
  @Prop() dashboard!: boolean;
  @Store() propertyTypeSearchStore!: PropertyTypeSearchStore;

  get avatar(): string {
    return this.userProfile?.profile?.avatar || '';
  }

  get firstName(): string {
    return this.userProfile?.profile?.firstName || '';
  }

  get lastName(): string {
    return this.userProfile?.profile?.lastName || '';
  }

  linkGenerator(link: string) {
    return `/${this.lang}${link}`;
  }

  get email(): string {
    return this.userProfile?.user?.email || '';
  }

  async logout() {
    this._goHome();
    await this.$fire.auth.signOut();
    this.$store.commit('userProfile/SIGN_OUT');
    this.evaluationsStore.resetStore();
  }

  private _goHome() {
    this.$router.push(`/${this.lang}`);
  }

  auth = {
    title: {
      de: 'Abmelden',
      en: 'Sign Out'
    }
  };

  get bargains() {
    return {
      title: this.$i18n.t('navbar.user-profile.bargains.title'),
      link: this.$i18n.t('navbar.user-profile.bargains.link')
    };
  }

  items = [
    {
      title: {
        de: 'Übersicht',
        en: 'Dashboard'
      },
      link: {
        en: '/dashboard',
        de: '/dashboard'
      }
    },
    {
      title: {
        de: 'Konto Einstellungen',
        en: 'Account Settings'
      },
      link: {
        en: '/settings',
        de: '/einstellungen'
      }
    },
    {
      title: {
        en: 'Purchases',
        de: 'Einkäufe'
      },
      link: {
        en: '/settings#purchases',
        de: '/einstellungen#purchases'
      }
    }
  ];

  resetStoreToDefault(link: string) {
    link === '/en/dashboard' &&
      this.propertyTypeSearchStore.setNewEvaluation(false);
  }
}
