import { NuxtFireInstance } from '@nuxtjs/firebase';
import firebase from 'firebase/compat';
import { AxiosRequestConfig } from 'axios';
import { inject, singleton } from 'tsyringe';
import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import UserProfile from '~/store/userProfile';
import { $axios } from '~/utils/api';

@singleton()
export class ApiService {
  private readonly axiosInstance: typeof $axios;
  private readonly userProfileStore: UserProfile;
  private readonly fire: NuxtFireInstance;

  constructor(@inject('store') private readonly store: Store<any>) {
    this.fire = store.$fire;
    this.axiosInstance = $axios;
    this.userProfileStore = getModule(UserProfile, this.store);
    this.setupRequestInterceptor();
  }

  private setupRequestInterceptor() {
    this.axiosInstance.onRequest(async (config) => {
      if (config.headers.Authorization !== undefined) {
        return config;
      }
      let user: firebase.User;
      if (this.fire.auth.currentUser) {
        user = this.fire.auth.currentUser;
      } else {
        const credential = await this.fire.auth.signInAnonymously();
        user = credential.user!;
      }
      const token = await user.getIdToken();

      config.headers.Authorization = `Bearer ${token}`;

      return config;
    });
  }

  public get<ResponseType>(url: string, config: AxiosRequestConfig = {}) {
    return this.axiosInstance.get<ResponseType>(url, config);
  }

  public post<ResponseType>(
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ) {
    return this.axiosInstance.post<ResponseType>(url, data, config);
  }

  public put<ResponseType>(
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ) {
    return this.axiosInstance.put<ResponseType>(url, data, config);
  }

  public delete<ResponseType>(url: string, config: AxiosRequestConfig = {}) {
    return this.axiosInstance.delete<ResponseType>(url, config);
  }

  public patch<ResponseType>(
    url: string,
    data: any,
    config: AxiosRequestConfig = {}
  ) {
    return this.axiosInstance.patch<ResponseType>(url, data, config);
  }

  public head<ResponseType>(url: string, config: AxiosRequestConfig = {}) {
    return this.axiosInstance.head<ResponseType>(url, config);
  }

  public options<ResponseType>(url: string, config: AxiosRequestConfig = {}) {
    return this.axiosInstance.options<ResponseType>(url, config);
  }

  public request<ResponseType>(config: AxiosRequestConfig) {
    return this.axiosInstance.request<ResponseType>(config);
  }
}
