
import Footer from '@/components/footer/Footer.vue';
import Navbar from '@/components/navbar/Navbar.vue';
export default {
  components: {
    Footer,
    Navbar
  }
  // enable redirect so far becuse we don't have multi market yet

  // beforeCreate() {
  //   if (this.$store.state.multiMarket.selected) {
  //     const country = this.$store.state.multiMarket.country;
  //     const path = this.$route.fullPath;
  //     if (!path.includes(`/${country}`)) {
  //       const pathAsArray = path.split('/');
  //       pathAsArray[1] = country;
  //       window.location.pathname = pathAsArray.join('/');
  //     }
  //   }
  // }
};
