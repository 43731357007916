import { inject, singleton } from 'tsyringe';
import { Store } from 'vuex';
import { ApiService } from '~/services/api.service';
import { sendMail } from '~/schema/country';
@singleton()
export class MultiMarket {
  constructor(
    private readonly apiService: ApiService,

    @inject('store') private readonly store: Store<any>
  ) {}

  async storeMail(data: sendMail) {
    const response = await this.apiService.post<sendMail>(
      '/landing-page/mailing-list/',
      data
    );
    this.store.commit('multiMarket/SET_SUBMITEMAIL', true);
    return response;
  }
}
