import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IRefinedEvaluation } from '@/entities/refined-evaluation';
import type { ExposeExternalDataType } from '@/schema/expose.api';
import type { EditEvaluationRequestSchema } from '@/schema/api';
import RefinedEvaluation from './refinedEvaluation';

@Module({
  name: 'exposeExternal',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class ExposeExternal extends VuexModule {
  public ExposeExternal: ExposeExternalDataType | null = null;

  @Mutation
  setData(exposeData: ExposeExternalDataType) {
    this.ExposeExternal = exposeData;
    const listing: EditEvaluationRequestSchema = exposeData.listing;
    this.features = RefinedEvaluation.mapToLocalState(listing);
  }

  features: IRefinedEvaluation = {
    heatingDemand: undefined,
    heatingDemandClass: undefined,
    numberOfFloors: undefined,
    floor: undefined,
    lift: undefined,
    cellar: undefined,
    cellarArea: undefined,
    bikeRoom: undefined,
    storeRoom: undefined,
    washingDryingRoom: undefined,
    suitableForSharedApartment: undefined,
    isVacationProperty: undefined,
    apartmentOrientation: undefined,
    heatingCosts: undefined,
    operationalCosts: undefined,
    newBuilding: undefined,
    firstTimeUse: undefined,
    condition: undefined,
    numberOfBathrooms: undefined,
    bathroomBathTube: undefined,
    bathroomBidet: undefined,
    bathroomWindow: undefined,
    bathroomShower: undefined,
    numberOfToilets: undefined,
    guestToilet: undefined,
    bathroomUrinal: undefined,
    numberOfBedrooms: undefined,
    kitchen: undefined,
    heatingType: undefined,
    firingType: undefined,
    floorType: undefined,
    sauna: undefined,
    fireplace: undefined,
    airConditioning: undefined,
    furnished: undefined,
    outdoorAreas: undefined,
    numberOfBalconies: undefined,
    balconyArea: undefined,
    balconyOrientation: undefined,
    numberOfLoggias: undefined,
    loggiaArea: undefined,
    loggiaOrientation: undefined,
    numberOfTerraces: undefined,
    terraceArea: undefined,
    terraceOrientation: undefined,
    gardenArea: undefined,
    gardenOrientation: undefined,
    view: undefined,
    parkingSpace: undefined,
    numberOfParkingSpaces: undefined,
    handicappedAccessible: undefined,
    swimmingPool: undefined,
    winterGarden: undefined,
    shutter: undefined
  };

  @Mutation
  setExposeFeature(refinedEvaluation: EditEvaluationRequestSchema) {
    this.features = RefinedEvaluation.mapToLocalState(refinedEvaluation);
  }

  get getFeature() {
    return <K extends keyof IRefinedEvaluation = keyof IRefinedEvaluation>(
      name: K
    ) => this.features[name];
  }
}
