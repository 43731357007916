import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { container } from 'tsyringe';
import type {
  EditEvaluationResponseSchema,
  GetEvaluationResponseSchema
} from '~/schema/api';
import type { CurrentEvaluation } from '~/entities/evaluation';
import { RefinedEvaluationService } from '~/services/refined-evaluation.service';

@Module({
  name: 'evaluations',
  stateFactory: true,
  namespaced: true
})
export default class EvaluationsStore extends VuexModule {
  // selected evaluation from the sidebar list
  // result of get request
  public selectedEvaluation: EditEvaluationResponseSchema | null = null;
  // list of evaluations result of get list request
  public evaluations: GetEvaluationResponseSchema[] = [];
  // current evaluation that is being created
  // result of post request and patch request
  public currentEvaluation: CurrentEvaluation = {
    id: null,
    listing: null,
    chartDetails: null,
    priceDetails: null
  };

  @Mutation
  setEvaluations(evaluations: GetEvaluationResponseSchema[]) {
    this.evaluations = evaluations.reverse();
  }

  @Mutation
  setSelectedEvaluation(evaluation: EditEvaluationResponseSchema) {
    this.selectedEvaluation = evaluation;

    container.resolve(RefinedEvaluationService).fillRefinedEvaluationStore();
  }

  @Mutation
  removeEvaluation(id: GetEvaluationResponseSchema['id']) {
    this.evaluations = this.evaluations.filter(
      (evaluation) => evaluation.id !== id
    );
  }

  @Mutation
  setCurrentEvaluation(evaluation: Partial<CurrentEvaluation>) {
    this.currentEvaluation.id = evaluation.id || null;
    this.currentEvaluation.listing = evaluation.listing || null;
    this.currentEvaluation.chartDetails = evaluation.chartDetails || null;
    this.currentEvaluation.priceDetails = evaluation?.priceDetails || null;
  }

  @Mutation
  setPriceDetails(priceDetails: CurrentEvaluation['priceDetails']) {
    this.currentEvaluation.priceDetails = priceDetails || null;
  }

  @Mutation
  setChartDetails(chartDetails: CurrentEvaluation['chartDetails']) {
    this.currentEvaluation.chartDetails = chartDetails;
  }

  @Mutation
  resetSteps() {
    this.currentEvaluation.chartDetails = null;
  }

  @Mutation
  resetStore() {
    this.evaluations = [];
    this.currentEvaluation = {
      id: null,
      listing: null,
      chartDetails: null,
      priceDetails: null
    };
  }

  @Mutation
  resetCurrentEvaluation() {
    this.currentEvaluation = {
      id: null,
      listing: null,
      chartDetails: null,
      priceDetails: null
    };
  }

  get hasCurrentEvaluation() {
    return this.currentEvaluation.id !== null;
  }

  get hasSelectedEvaluation() {
    return this.selectedEvaluation !== null;
  }

  get isSelectedEvaluationComplete() {
    return (
      this.selectedEvaluation &&
      this.selectedEvaluation.listing !== null &&
      this.selectedEvaluation.density !== null &&
      this.selectedEvaluation?.priceDetails !== null
    );
  }

  get hasEvaluations(): boolean {
    return this.evaluations.length > 0;
  }

  get selectedEvaluationPreferredPriceDetails() {
    return this.selectedEvaluation
      ? this.selectedEvaluation.priceDetails.refined
        ? this.selectedEvaluation.priceDetails.refined
        : this.selectedEvaluation.priceDetails.basic
      : {
          rangeMax: 0,
          rangeMean: 0,
          rangeMin: 0
        };
  }

  get selectedEvaluationHasDoneRefined() {
    return !!this.selectedEvaluation?.priceDetails.refined;
  }

  findEvaluationById(id: GetEvaluationResponseSchema['id']) {
    return this.evaluations.find((evaluation) => evaluation.id === id);
  }
}
