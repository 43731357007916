import { inject, singleton } from 'tsyringe';
import { Store } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '~/services/api.service';

@singleton()
export class PartnerPageService {
  constructor(
    private readonly apiService: ApiService,

    @inject('store') private readonly store: Store<any> // @inject('fire') private readonly fire: firebase<any>
  ) {}

  date() {
    const date = new Date().toLocaleDateString('en-US');
    const spiltDate = date.split('/');
    return `${spiltDate[0]}/${spiltDate[2]}`;
  }

  async trackBtn(page: string, btn: any) {
    const date = this.date();
    let createNew = true;
    await this.store.$fire.firestore
      .collection('partner-page-button-tracker')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          if (
            data.page === page &&
            data.url === btn.link &&
            data.date === date
          ) {
            doc.ref.update({ count: data.count + 1 });
            createNew = false;
          }
        });
      });
    if (createNew) {
      await this.store.$fire.firestore
        .collection('partner-page-button-tracker')
        .doc(uuidv4())
        .set({
          page,
          url: btn.link,
          date: this.date(),
          count: 1
        });
    }
  }

  compare(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  async getPartnerPage() {
    const response = await this.apiService.get<any>(
      '/admin/page-builder/?page=1&size=12&sort_by=dateCreated&title=partner_page'
    );

    const data = response.data.sort(this.compare);
    this.store.commit('partnerPage/setDataFromApi', data);
    return response;
  }
}
