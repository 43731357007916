enum Country {
  AT = 'at',
  DE = 'de',
  CH = 'ch',
  EN = 'en'
}
enum selectedCountry {
  germany = 'Germany',
  switzerland = 'Switzerland'
}

interface sendMail {
  email: string;
  country?: string;
}

export { Country, selectedCountry, sendMail };
