import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type { EditEvaluationRequestSchema } from '~/schema/api';
import {
  Accessibility,
  Age,
  BalconyTerraceOrientation,
  Bathroom,
  EditRefinedEvaluationRequestSchema,
  Facilities,
  FiringType,
  Floor,
  Furnishing,
  HeatingType,
  Kitchen,
  Lift,
  ParkingSpace,
  RoutersListingModelConditionType,
  View
} from '~/schema/api';
import { Debounce } from '~/decorators/debounce';
import {
  ConditionOptions,
  FiringTypeOptions,
  FloorTypeOptions,
  HeatingTypeOptions,
  IRefinedEvaluation,
  KitchenOptions,
  OrientationOptions,
  OutdoorAreasOptions,
  ParkingSpaceOptions,
  PolarOptions,
  ViewOptions
} from '~/entities/refined-evaluation';
import {
  ifArrayNotEmpty,
  ifDefinedOtherwiseUndefined,
  switchCaseMap
} from '~/utils/utils';
import { reverseItems } from '~/utils/array';

type DependantFeature<
  K extends keyof IRefinedEvaluation = keyof IRefinedEvaluation
> = [K, IRefinedEvaluation[K] | void];

@Module({
  name: 'refinedEvaluation',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class RefinedEvaluation extends VuexModule {
  step = 1;

  showForm = false;

  features: IRefinedEvaluation = {
    heatingDemand: undefined,
    heatingDemandClass: undefined,
    numberOfFloors: undefined,
    floor: undefined,
    lift: undefined,
    isOwner: undefined,
    transactionPriceHistory: undefined,
    isRented: undefined,
    rentPrice: undefined,
    rentDate: undefined,
    fixedTermLease: undefined,
    fixedTermLeaseDate: undefined,
    heatingCosts: undefined,
    heatingTax: 20,
    heatingGross: undefined,
    operationalCosts: undefined,
    operationalTax: 10,
    operationalGross: undefined,
    additionalCosts: undefined,
    additionalTax: 20,
    additionalGross: undefined,
    electricityCosts: undefined,
    electricityTax: 20,
    electricityGross: undefined,
    cellar: undefined,
    cellarArea: undefined,
    bikeRoom: undefined,
    storeRoom: undefined,
    washingDryingRoom: undefined,
    suitableForSharedApartment: undefined,
    isVacationProperty: undefined,
    apartmentOrientation: undefined,
    newBuilding: undefined,
    firstTimeUse: undefined,
    condition: undefined,
    numberOfBathrooms: undefined,
    bathroomBathTube: undefined,
    bathroomBidet: undefined,
    bathroomWindow: undefined,
    bathroomShower: undefined,
    numberOfToilets: undefined,
    guestToilet: undefined,
    bathroomUrinal: undefined,
    numberOfBedrooms: undefined,
    kitchen: undefined,
    heatingType: undefined,
    firingType: undefined,
    floorType: undefined,
    sauna: undefined,
    fireplace: undefined,
    airConditioning: undefined,
    furnished: undefined,
    outdoorAreas: undefined,
    numberOfBalconies: undefined,
    balconyArea: undefined,
    balconyOrientation: undefined,
    numberOfLoggias: undefined,
    loggiaArea: undefined,
    loggiaOrientation: undefined,
    numberOfTerraces: undefined,
    terraceArea: undefined,
    terraceOrientation: undefined,
    gardenArea: undefined,
    gardenOrientation: undefined,
    view: undefined,
    parkingSpace: undefined,
    numberOfBasementGarageSpaces: undefined,
    numberOfCarportSpaces: undefined,
    numberOfDuplexSpaces: undefined,
    numberOfGarageSpaces: undefined,
    numberOfParkingDeckSpaces: undefined,
    numberOfParkingSpaces: undefined,
    handicappedAccessible: undefined,
    swimmingPool: undefined,
    winterGarden: undefined,
    shutter: undefined
  };

  static dependantFeatureKeys: Partial<
    Record<keyof IRefinedEvaluation, DependantFeature>
  > = {
    balconyArea: ['outdoorAreas', OutdoorAreasOptions.BALCONY],
    balconyOrientation: ['outdoorAreas', OutdoorAreasOptions.BALCONY],
    numberOfBalconies: ['outdoorAreas', OutdoorAreasOptions.BALCONY],
    loggiaArea: ['outdoorAreas', OutdoorAreasOptions.LOGGIA],
    loggiaOrientation: ['outdoorAreas', OutdoorAreasOptions.LOGGIA],
    numberOfLoggias: ['outdoorAreas', OutdoorAreasOptions.LOGGIA],
    terraceArea: ['outdoorAreas', OutdoorAreasOptions.TERRACE],
    terraceOrientation: ['outdoorAreas', OutdoorAreasOptions.TERRACE],
    numberOfTerraces: ['outdoorAreas', OutdoorAreasOptions.TERRACE],
    gardenArea: ['outdoorAreas', OutdoorAreasOptions.GARDEN],
    gardenOrientation: ['outdoorAreas', OutdoorAreasOptions.GARDEN],
    numberOfParkingSpaces: ['parkingSpace', ParkingSpaceOptions.PARKING_SPACE],
    numberOfBasementGarageSpaces: [
      'parkingSpace',
      ParkingSpaceOptions.BASEMENT_GARAGE
    ],
    numberOfCarportSpaces: ['parkingSpace', ParkingSpaceOptions.CARPORT],
    numberOfDuplexSpaces: ['parkingSpace', ParkingSpaceOptions.DUPLEX],
    numberOfGarageSpaces: ['parkingSpace', ParkingSpaceOptions.GARAGE],
    numberOfParkingDeckSpaces: [
      'parkingSpace',
      ParkingSpaceOptions.PARKING_DECK
    ]
  };

  static maps = {
    age: [
      [PolarOptions.Yes, Age.NEWBUILDING],
      [PolarOptions.No, Age.OLDBUILDING]
    ] as [PolarOptions, Age][],
    type: [
      [
        ConditionOptions.FIRST_TIME_USE,
        RoutersListingModelConditionType.FIRST_TIME_USE
      ],
      [
        ConditionOptions.FULLY_RENOVATED,
        RoutersListingModelConditionType.FULLY_RENOVATED
      ],
      [
        ConditionOptions.MINT_CONDITION,
        RoutersListingModelConditionType.MINT_CONDITION
      ],
      [
        ConditionOptions.NEED_OF_RENOVATION,
        RoutersListingModelConditionType.NEED_OF_RENOVATION
      ],
      [
        ConditionOptions.REFURBISHED,
        RoutersListingModelConditionType.REFURBISHED
      ],
      [ConditionOptions.WELL_KEPT, RoutersListingModelConditionType.WELL_KEPT]
    ] as [ConditionOptions, RoutersListingModelConditionType][],
    handicappedAccessible: [
      [PolarOptions.Yes, [Accessibility.HANDICAPPED_ACCESSIBLE]],
      [PolarOptions.No, []]
    ] as [PolarOptions, Accessibility[]][],
    lift: [
      [PolarOptions.Yes, [Lift.LIFT]],
      [PolarOptions.Yes, []]
    ] as [PolarOptions, Lift[]][],
    floorType: [
      [FloorTypeOptions.CARPET, Floor.CARPET],
      [FloorTypeOptions.BOARDS, Floor.BOARDS],
      [FloorTypeOptions.CARPET, Floor.CARPET],
      [FloorTypeOptions.GRANITE, Floor.GRANITE],
      [FloorTypeOptions.LAMINATE, Floor.LAMINATE],
      [FloorTypeOptions.LINOLEUM, Floor.LINOLEUM],
      [FloorTypeOptions.MARBLE, Floor.MARBLE],
      [FloorTypeOptions.PARQUET, Floor.PARQUET],
      [FloorTypeOptions.PLASTIC, Floor.PLASTIC],
      [FloorTypeOptions.SCREED, Floor.SCREED],
      [FloorTypeOptions.STONE, Floor.STONE],
      [FloorTypeOptions.TERRACOTTA, Floor.TERRACOTTA],
      [FloorTypeOptions.TILES, Floor.TILES]
    ] as [FloorTypeOptions, Floor][],
    balconyOrientation: [
      [OrientationOptions.NORTH, BalconyTerraceOrientation.NORTH],
      [OrientationOptions.NORTH_EAST, BalconyTerraceOrientation.NORTH_EAST],
      [OrientationOptions.NORTH_WEST, BalconyTerraceOrientation.NORTH_WEST],
      [OrientationOptions.SOUTH, BalconyTerraceOrientation.SOUTH],
      [OrientationOptions.SOUTH_EAST, BalconyTerraceOrientation.SOUTH_EAST],
      [OrientationOptions.SOUTH_WEST, BalconyTerraceOrientation.SOUTH_WEST],
      [OrientationOptions.EAST, BalconyTerraceOrientation.EAST],
      [OrientationOptions.WEST, BalconyTerraceOrientation.WEST]
    ] as [OrientationOptions, BalconyTerraceOrientation][],
    firingType: [
      [FiringTypeOptions.AIR_HEAT, [FiringType.AIR_HEAT]],
      [FiringTypeOptions.ALTERNATIVE, [FiringType.ALTERNATIVE]],
      [FiringTypeOptions.BLOCK_HEATING, [FiringType.BLOCK_HEATING]],
      [FiringTypeOptions.COAL, [FiringType.COAL]],
      [FiringTypeOptions.DISTRICT_HEATING, [FiringType.DISTRICT_HEATING]],
      [FiringTypeOptions.ELECTRICITY, [FiringType.ELECTRICITY]],
      [FiringTypeOptions.GAS, [FiringType.GAS]],
      [FiringTypeOptions.GEOTHERMAL, [FiringType.GEOTHERMAL]],
      [FiringTypeOptions.OIL, [FiringType.OIL]],
      [FiringTypeOptions.PELLET_HEATING, [FiringType.PELLET_HEATING]],
      [FiringTypeOptions.SOLAR_HEATING, [FiringType.SOLAR_HEATING]],
      [
        FiringTypeOptions.WATER_ELECTRICITY_HEATING,
        [FiringType.WATER_ELECTRICITY_HEATING]
      ]
    ] as [FiringTypeOptions, FiringType[]][],
    heatingType: [
      [HeatingTypeOptions.CENTRAL_HEATING, [HeatingType.CENTRAL_HEATING]],
      [HeatingTypeOptions.DISTRICT_HEATING, [HeatingType.DISTRICT_HEATING]],
      [HeatingTypeOptions.FLOOR_HEATING, [HeatingType.FLOOR_HEATING]],
      [
        HeatingTypeOptions.SELF_CONTAINED_CENTRAL_HEATING,
        [HeatingType.SELF_CONTAINED_CENTRAL_HEATING]
      ],
      [HeatingTypeOptions.STOVE_HEATING, [HeatingType.STOVE_HEATING]]
    ] as [HeatingTypeOptions, HeatingType[]][],
    furnishing: [
      [PolarOptions.Yes, Furnishing.FULL],
      [PolarOptions.No, Furnishing.PART]
    ] as [PolarOptions, Furnishing][],
    kitchen: [
      [KitchenOptions.OPEN_KITCHEN, [Kitchen.OPEN_KITCHEN]],
      [KitchenOptions.FITTED_KITCHEN, [Kitchen.FITTED_KITCHEN]],
      [KitchenOptions.KITCHENETTE, [Kitchen.KITCHENETTE]],
      [KitchenOptions.NO_KITCHEN, []]
    ] as [KitchenOptions, Kitchen[] | undefined][],
    parkingSpace: [
      [ParkingSpaceOptions.BASEMENT_GARAGE, ParkingSpace.BASEMENT_GARAGE],
      [ParkingSpaceOptions.CARPORT, ParkingSpace.CARPORT],
      [ParkingSpaceOptions.DUPLEX, ParkingSpace.DUPLEX],
      [ParkingSpaceOptions.GARAGE, ParkingSpace.GARAGE],
      [ParkingSpaceOptions.PARKING_SPACE, ParkingSpace.PARKING_SPACE],
      [ParkingSpaceOptions.PARKING_DECK, ParkingSpace.PARKING_DECK]
    ] as [ParkingSpaceOptions, ParkingSpace][],

    view: [
      [ViewOptions.AFAR, View.AFAR],
      [ViewOptions.SEE, View.LAKE],
      [ViewOptions.MOUNTAINS, View.MOUNTAINS]
    ] as [ViewOptions, View][]
  };

  static mapToSchema(
    features: IRefinedEvaluation
  ): EditRefinedEvaluationRequestSchema {
    return {
      area: {
        balconyArea:
          features.outdoorAreas?.includes(OutdoorAreasOptions.BALCONY) !==
          undefined
            ? features.balconyArea
            : null,
        gardenArea:
          features.outdoorAreas?.includes(OutdoorAreasOptions.GARDEN) !==
          undefined
            ? features.gardenArea
            : null,
        loggiaArea:
          features.outdoorAreas?.includes(OutdoorAreasOptions.LOGGIA) !==
          undefined
            ? features.loggiaArea
            : null,
        terraceArea:
          features.outdoorAreas?.includes(OutdoorAreasOptions.TERRACE) !==
          undefined
            ? features.terraceArea
            : null,
        cellarArea: features.cellarArea,
        numberOfBalconies: features.numberOfBalconies,
        numberOfLoggia: features.numberOfLoggias,
        numberOfTerrace: features.numberOfTerraces,
        numberOfBathrooms: features.numberOfBathrooms,
        numberOfBedrooms: features.numberOfBedrooms,
        numberOfToilets: features.numberOfToilets,
        hasBalcony: features.outdoorAreas?.includes(OutdoorAreasOptions.BALCONY)
          ? true
          : false,
        hasGarden: features.outdoorAreas?.includes(OutdoorAreasOptions.GARDEN)
          ? true
          : false,
        hasLoggia: features.outdoorAreas?.includes(OutdoorAreasOptions.LOGGIA)
          ? true
          : false,
        hasTerrace: features.outdoorAreas?.includes(OutdoorAreasOptions.TERRACE)
          ? true
          : false
      },
      type: {
        propertyTypeAttribute: {
          isVacationProperty: ifDefinedOtherwiseUndefined(
            features.isVacationProperty,
            () => features.isVacationProperty === PolarOptions.Yes
          )
        }
      },
      fitting: {
        accessibility: ifDefinedOtherwiseUndefined(
          features.handicappedAccessible,
          () =>
            switchCaseMap(
              features.handicappedAccessible,
              this.maps.handicappedAccessible
            )
        ),

        lift: ifDefinedOtherwiseUndefined(features.lift, () =>
          switchCaseMap(features.lift, this.maps.lift)
        ),

        floor: ifDefinedOtherwiseUndefined(
          features.floorType,
          () =>
            features.floorType!.map((floorType) =>
              switchCaseMap(floorType, this.maps.floorType)
            ) as Floor[]
        ),

        suitableForSharedApartment: ifDefinedOtherwiseUndefined(
          features.suitableForSharedApartment,
          () =>
            switchCaseMap(features.suitableForSharedApartment, [
              [PolarOptions.Yes, true],
              [PolarOptions.No, false]
            ])
        ),

        balconyOrientation: ifDefinedOtherwiseUndefined(
          features.outdoorAreas?.includes(OutdoorAreasOptions.BALCONY)
            ? features.balconyOrientation
            : undefined,
          () =>
            features.balconyOrientation!.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),

        orientation: ifDefinedOtherwiseUndefined(
          features.apartmentOrientation,
          () =>
            features.apartmentOrientation!.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),

        loggiaOrientation: ifDefinedOtherwiseUndefined(
          features.outdoorAreas?.includes(OutdoorAreasOptions.LOGGIA)
            ? features.loggiaOrientation
            : undefined,
          () =>
            features.loggiaOrientation!.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),

        terraceOrientation: ifDefinedOtherwiseUndefined(
          features.outdoorAreas?.includes(OutdoorAreasOptions.TERRACE)
            ? features.loggiaOrientation
            : undefined,
          () =>
            features.loggiaOrientation!.map((orientation) =>
              switchCaseMap(orientation, this.maps.balconyOrientation)
            ) as BalconyTerraceOrientation[]
        ),

        bathroom: ifArrayNotEmpty(
          Object.keys(Bathroom)
            .map((bathroomType) =>
              switchCaseMap(bathroomType, [
                [
                  Bathroom.BIDET,
                  features.bathroomBidet === PolarOptions.Yes && Bathroom.BIDET
                ],
                [
                  Bathroom.BATHTUBE,
                  features.bathroomBathTube === PolarOptions.Yes &&
                    Bathroom.BATHTUBE
                ],
                [
                  Bathroom.SHOWER,
                  features.bathroomShower === PolarOptions.Yes &&
                    Bathroom.SHOWER
                ],
                [
                  Bathroom.URINAL,
                  features.bathroomUrinal === PolarOptions.Yes &&
                    Bathroom.URINAL
                ],
                // [
                //   Bathroom.SAUNA,
                //   features.sauna === PolarOptions.Yes && Bathroom.SAUNA
                // ],
                [
                  Bathroom.WINDOW,
                  features.bathroomWindow === PolarOptions.Yes &&
                    Bathroom.WINDOW
                ]
              ])
            )
            .filter((bathroom) => bathroom !== false) as Bathroom[]
        ),

        firingType: ifDefinedOtherwiseUndefined(features.firingType, () =>
          switchCaseMap(features.firingType, this.maps.firingType)
        ),

        heatingType: ifDefinedOtherwiseUndefined(features.heatingType, () =>
          switchCaseMap(features.heatingType, this.maps.heatingType)
        ),

        facilities: ifArrayNotEmpty(
          [
            features.cellar === PolarOptions.Yes && Facilities.CELLAR,
            features.cellar === PolarOptions.No && Facilities.CELLAR_PARTIAL,
            features.bikeRoom === PolarOptions.Yes && Facilities.BIKE_ROOM,
            features.storeRoom === PolarOptions.Yes && Facilities.STOREROOM,
            features.washingDryingRoom === PolarOptions.Yes &&
              Facilities.WASHING_DRYING_ROOM,
            features.guestToilet === PolarOptions.Yes &&
              Facilities.GUEST_TOILET,
            features.fireplace === PolarOptions.Yes && Facilities.FIREPLACE,
            features.airConditioning === PolarOptions.Yes &&
              Facilities.AIR_CONDITIONING,
            features.swimmingPool === PolarOptions.Yes &&
              Facilities.SWIMMING_POOL,
            features.winterGarden === PolarOptions.Yes &&
              Facilities.WINTER_GARDEN,
            features.shutter === PolarOptions.Yes && Facilities.SHUTTER,
            features.sauna === PolarOptions.Yes && Facilities.SAUNA
          ].filter((facility) => facility !== false) as Facilities[]
        ),

        furnishing: ifDefinedOtherwiseUndefined(features.furnished, () =>
          switchCaseMap(features.furnished, this.maps.furnishing)
        ),

        kitchen: ifDefinedOtherwiseUndefined(features.kitchen, () =>
          switchCaseMap(features.kitchen, this.maps.kitchen)
        ),

        parkingSpaceGarageNumber: features.numberOfGarageSpaces,

        parkingSpaceOutsideNumber: features.numberOfParkingSpaces,

        parkingSpaceBasementGarageNumber: features.numberOfBasementGarageSpaces,

        parkingSpaceCarportNumber: features.numberOfCarportSpaces,

        parkingSpaceDuplexNumber: features.numberOfDuplexSpaces,

        parkingSpaceParkingDeckNumber: features.numberOfParkingDeckSpaces,

        parkingSpace: ifDefinedOtherwiseUndefined(
          features.parkingSpace,
          () =>
            features.parkingSpace?.map((parkingSpace) =>
              switchCaseMap(parkingSpace, this.maps.parkingSpace)
            ) as ParkingSpace[]
        ),

        view: ifDefinedOtherwiseUndefined(
          features.view,
          () =>
            features.view?.map((view) =>
              switchCaseMap(view, this.maps.view)
            ) as View[]
        )
      },
      condition: {
        energyCertification: {
          heatingDemand: features.heatingDemand,
          heatingDemandClass: features.heatingDemandClass
        },

        age: ifDefinedOtherwiseUndefined(features.newBuilding, () =>
          switchCaseMap(features.newBuilding, this.maps.age)
        ),

        type: ifDefinedOtherwiseUndefined(features.condition, () =>
          switchCaseMap(features.condition, this.maps.type)
        )
      },
      priceInformation: {
        costs: {
          heatingCosts: {
            net: features.heatingGross
              ? this.calculateNetCosts(
                  features.heatingCosts,
                  features.heatingTax
                )
              : features.heatingCosts,
            tax: this.calculateTaxCosts(
              !!features.heatingGross,
              features.heatingCosts,
              features.heatingTax
            ),
            showGross: features.heatingGross
          },
          operationalCosts: {
            net: features.operationalGross
              ? this.calculateNetCosts(
                  features.operationalCosts,
                  features.operationalTax
                )
              : features.operationalCosts,
            tax: this.calculateTaxCosts(
              !!features.operationalGross,
              features.operationalCosts,
              features.operationalTax
            ),
            showGross: features.operationalGross
          },
          electricityCosts: {
            net: features.electricityGross
              ? this.calculateNetCosts(
                  features.electricityCosts,
                  features.electricityTax
                )
              : features.electricityCosts,
            tax: this.calculateTaxCosts(
              !!features.electricityGross,
              features.electricityCosts,
              features.electricityTax
            ),
            showGross: features.electricityGross
          },
          miscCosts: {
            net: features.additionalGross
              ? this.calculateNetCosts(
                  features.additionalCosts,
                  features.additionalTax
                )
              : features.additionalCosts,
            tax: this.calculateTaxCosts(
              !!features.additionalGross,
              features.additionalCosts,
              features.additionalTax
            ),
            showGross: features.additionalGross
          }
        },
        IREENUser: {
          leaseholdTerm: ifDefinedOtherwiseUndefined(
            features.fixedTermLease,
            () =>
              switchCaseMap(features.fixedTermLease, [
                [PolarOptions.Yes, true],
                [PolarOptions.No, false]
              ])
          ),
          leaseholdTermEnd: features.fixedTermLeaseDate,
          CurrentMainRent: features.rentPrice,
          CurrentRentContractStart: features.rentDate,
          TransactionPriceHistory: ifDefinedOtherwiseUndefined(
            features.transactionPriceHistory,
            () =>
              ifArrayNotEmpty(
                features.transactionPriceHistory?.filter(
                  (item) => item.price && item.date
                ) || []
              )
          )
        }
      },
      localization: {
        information: {
          numberOfFloors: features.numberOfFloors,
          floor: features.floor
        }
      },
      meta: {
        IREENUser: {
          IsOwner: ifDefinedOtherwiseUndefined(features.isOwner, () =>
            switchCaseMap(features.isOwner, [
              [PolarOptions.Yes, true],
              [PolarOptions.No, false]
            ])
          )
        }
      },
      object: {
        IsRented: ifDefinedOtherwiseUndefined(features.isRented, () =>
          switchCaseMap(features.isRented, [
            [PolarOptions.Yes, true],
            [PolarOptions.No, false]
          ])
        )
      }
    };
  }

  static mapToLocalState(
    schema: EditRefinedEvaluationRequestSchema
  ): IRefinedEvaluation {
    return {
      estateType: schema.type?.estateType,
      estateDetailType: schema.type?.estateDetailType,
      yearOfConstruction: schema.condition?.yearOfConstruction,
      heatingDemand: schema.condition?.energyCertification?.heatingDemand,
      heatingDemandClass:
        schema.condition?.energyCertification?.heatingDemandClass,
      numberOfFloors: schema.localization?.information?.numberOfFloors,
      floor: schema.localization?.information?.floor,
      lift: ifDefinedOtherwiseUndefined(schema.fitting?.lift, () =>
        switchCaseMap(true, [
          [schema.fitting?.lift == null, PolarOptions.No],
          [schema.fitting?.lift?.includes(Lift.LIFT), PolarOptions.Yes]
        ])
      ),
      cellar: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.CELLAR)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      cellarArea: schema.area?.cellarArea,
      bikeRoom: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.BIKE_ROOM)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      storeRoom: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.STOREROOM)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      washingDryingRoom: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.WASHING_DRYING_ROOM)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      suitableForSharedApartment: ifDefinedOtherwiseUndefined(
        schema.fitting?.suitableForSharedApartment,
        () =>
          schema.fitting?.suitableForSharedApartment
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      isVacationProperty: ifDefinedOtherwiseUndefined(
        schema.type?.propertyTypeAttribute?.isVacationProperty,
        () =>
          schema.type?.propertyTypeAttribute?.isVacationProperty
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      heatingCosts: schema.priceInformation?.costs?.heatingCosts?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.heatingCosts?.net,
            schema.priceInformation?.costs?.heatingCosts?.tax
          )
        : schema.priceInformation?.costs?.heatingCosts?.net,
      heatingGross: schema.priceInformation?.costs?.heatingCosts?.showGross,
      heatingTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.heatingCosts?.net,
          schema.priceInformation?.costs?.heatingCosts?.tax
        ) || 20,
      operationalCosts: schema.priceInformation?.costs?.operationalCosts
        ?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.operationalCosts?.net,
            schema.priceInformation?.costs?.operationalCosts?.tax
          )
        : schema.priceInformation?.costs?.operationalCosts?.net,
      operationalGross:
        schema.priceInformation?.costs?.operationalCosts?.showGross,
      operationalTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.operationalCosts?.net,
          schema.priceInformation?.costs?.operationalCosts?.tax
        ) || 10,
      electricityCosts: schema.priceInformation?.costs?.electricityCosts
        ?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.electricityCosts?.net,
            schema.priceInformation?.costs?.electricityCosts?.tax
          )
        : schema.priceInformation?.costs?.electricityCosts?.net,
      electricityGross:
        schema.priceInformation?.costs?.electricityCosts?.showGross,
      electricityTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.electricityCosts?.net,
          schema.priceInformation?.costs?.electricityCosts?.tax
        ) || 20,
      additionalCosts: schema.priceInformation?.costs?.miscCosts?.showGross
        ? this.calculateGrossCosts(
            schema.priceInformation?.costs?.miscCosts?.net,
            schema.priceInformation?.costs?.miscCosts?.tax
          )
        : schema.priceInformation?.costs?.miscCosts?.net,
      additionalGross: schema.priceInformation?.costs?.miscCosts?.showGross,
      additionalTax:
        this.calculateTaxRate(
          schema.priceInformation?.costs?.miscCosts?.net,
          schema.priceInformation?.costs?.miscCosts?.tax
        ) || 20,
      newBuilding: ifDefinedOtherwiseUndefined(schema.condition?.age, () =>
        schema.condition?.age === Age.NEWBUILDING
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      // firstTimeUse: ,
      condition: ifDefinedOtherwiseUndefined(schema.condition?.type, () =>
        switchCaseMap(
          schema.condition?.type,
          reverseItems(this.maps.type) as [
            RoutersListingModelConditionType,
            ConditionOptions
          ][]
        )
      ),
      numberOfBathrooms: schema.area?.numberOfBathrooms,
      bathroomBathTube: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.BATHTUBE)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      bathroomBidet: ifDefinedOtherwiseUndefined(schema.fitting?.bathroom, () =>
        schema.fitting?.bathroom?.includes(Bathroom.BIDET)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      bathroomWindow: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.WINDOW)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      bathroomShower: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.SHOWER)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      numberOfToilets: schema.area?.numberOfToilets,
      guestToilet: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.GUEST_TOILET)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      bathroomUrinal: ifDefinedOtherwiseUndefined(
        schema.fitting?.bathroom,
        () =>
          schema.fitting?.bathroom?.includes(Bathroom.URINAL)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      numberOfBedrooms: schema.area?.numberOfBedrooms,
      kitchen: ifDefinedOtherwiseUndefined(schema.fitting?.kitchen, () =>
        switchCaseMap(true, [
          [
            schema.fitting?.kitchen?.includes(Kitchen.OPEN_KITCHEN),
            KitchenOptions.OPEN_KITCHEN
          ],
          [
            schema.fitting?.kitchen?.includes(Kitchen.FITTED_KITCHEN),
            KitchenOptions.FITTED_KITCHEN
          ],
          [
            schema.fitting?.kitchen?.includes(Kitchen.KITCHENETTE),
            KitchenOptions.KITCHENETTE
          ],
          [schema.fitting?.kitchen?.length === 0, KitchenOptions.NO_KITCHEN]
        ])
      ),
      heatingType: ifDefinedOtherwiseUndefined(
        schema.fitting?.heatingType,
        () =>
          switchCaseMap(true, [
            [
              schema.fitting?.heatingType?.includes(
                HeatingType.CENTRAL_HEATING
              ),
              HeatingTypeOptions.CENTRAL_HEATING
            ],
            [
              schema.fitting?.heatingType?.includes(
                HeatingType.DISTRICT_HEATING
              ),
              HeatingTypeOptions.DISTRICT_HEATING
            ],
            [
              schema.fitting?.heatingType?.includes(HeatingType.FLOOR_HEATING),
              HeatingTypeOptions.FLOOR_HEATING
            ],
            [
              schema.fitting?.heatingType?.includes(HeatingType.STOVE_HEATING),
              HeatingTypeOptions.STOVE_HEATING
            ],
            [
              schema.fitting?.heatingType?.includes(
                HeatingType.SELF_CONTAINED_CENTRAL_HEATING
              ),
              HeatingTypeOptions.SELF_CONTAINED_CENTRAL_HEATING
            ]
          ])
      ),
      firingType: ifDefinedOtherwiseUndefined(schema.fitting?.firingType, () =>
        switchCaseMap(true, [
          [
            schema.fitting?.firingType?.includes(FiringType.AIR_HEAT),
            FiringTypeOptions.AIR_HEAT
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.ALTERNATIVE),
            FiringTypeOptions.ALTERNATIVE
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.BLOCK_HEATING),
            FiringTypeOptions.BLOCK_HEATING
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.COAL),
            FiringTypeOptions.COAL
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.DISTRICT_HEATING),
            FiringTypeOptions.DISTRICT_HEATING
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.ELECTRICITY),
            FiringTypeOptions.ELECTRICITY
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.GAS),
            FiringTypeOptions.GAS
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.GEOTHERMAL),
            FiringTypeOptions.GEOTHERMAL
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.OIL),
            FiringTypeOptions.OIL
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.PELLET_HEATING),
            FiringTypeOptions.PELLET_HEATING
          ],
          [
            schema.fitting?.firingType?.includes(FiringType.SOLAR_HEATING),
            FiringTypeOptions.SOLAR_HEATING
          ],
          [
            schema.fitting?.firingType?.includes(
              FiringType.WATER_ELECTRICITY_HEATING
            ),
            FiringTypeOptions.WATER_ELECTRICITY_HEATING
          ]
        ])
      ),
      floorType: ifDefinedOtherwiseUndefined(schema.fitting?.floor, () =>
        ifArrayNotEmpty(
          schema.fitting?.floor
            ?.map((floor) =>
              switchCaseMap(
                floor,
                reverseItems(this.maps.floorType) as [Floor, FloorTypeOptions][]
              )
            )
            .filter((f) => f !== undefined) as FloorTypeOptions[]
        )
      ),
      sauna: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.SAUNA)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      fireplace: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.FIREPLACE)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      airConditioning: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.AIR_CONDITIONING)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      furnished: ifDefinedOtherwiseUndefined(schema.fitting?.furnishing, () =>
        switchCaseMap(
          schema.fitting?.furnishing,
          reverseItems(this.maps.furnishing) as [Furnishing, PolarOptions][]
        )
      ),
      outdoorAreas: ifArrayNotEmpty(
        [
          schema.area?.hasBalcony ? OutdoorAreasOptions.BALCONY : undefined,
          schema.area?.hasTerrace ? OutdoorAreasOptions.TERRACE : undefined,
          schema.area?.hasGarden ? OutdoorAreasOptions.GARDEN : undefined,
          schema.area?.hasLoggia ? OutdoorAreasOptions.LOGGIA : undefined
        ].filter((f) => f !== undefined) as OutdoorAreasOptions[]
      ),
      numberOfBalconies: schema.area?.numberOfBalconies,
      balconyOrientation: ifDefinedOtherwiseUndefined(
        schema.fitting?.balconyOrientation,
        () =>
          ifArrayNotEmpty(
            schema.fitting?.balconyOrientation
              ?.map((orientation) =>
                switchCaseMap(
                  orientation,
                  reverseItems(this.maps.balconyOrientation) as [
                    BalconyTerraceOrientation,
                    OrientationOptions
                  ][]
                )
              )
              .filter((f) => f !== undefined) as OrientationOptions[]
          )
      ),
      loggiaOrientation: ifDefinedOtherwiseUndefined(
        schema.fitting?.loggiaOrientation,
        () =>
          ifArrayNotEmpty(
            schema.fitting?.loggiaOrientation
              ?.map((orientation) =>
                switchCaseMap(
                  orientation,
                  reverseItems(this.maps.balconyOrientation) as [
                    BalconyTerraceOrientation,
                    OrientationOptions
                  ][]
                )
              )
              .filter((f) => f !== undefined) as OrientationOptions[]
          )
      ),
      apartmentOrientation: ifDefinedOtherwiseUndefined(
        schema.fitting?.orientation,
        () =>
          ifArrayNotEmpty(
            schema.fitting?.orientation
              ?.map((orientation) =>
                switchCaseMap(
                  orientation,
                  reverseItems(this.maps.balconyOrientation) as [
                    BalconyTerraceOrientation,
                    OrientationOptions
                  ][]
                )
              )
              .filter((f) => f !== undefined) as OrientationOptions[]
          )
      ),
      terraceOrientation: ifDefinedOtherwiseUndefined(
        schema.fitting?.terraceOrientation,
        () =>
          ifArrayNotEmpty(
            schema.fitting?.terraceOrientation
              ?.map((orientation) =>
                switchCaseMap(
                  orientation,
                  reverseItems(this.maps.balconyOrientation) as [
                    BalconyTerraceOrientation,
                    OrientationOptions
                  ][]
                )
              )
              .filter((f) => f !== undefined) as OrientationOptions[]
          )
      ),
      gardenOrientation: undefined,
      balconyArea: schema.area?.balconyArea,
      loggiaArea: schema.area?.loggiaArea,
      gardenArea: schema.area?.gardenArea,
      terraceArea: schema.area?.terraceArea,
      numberOfLoggias: schema.area?.numberOfLoggia,
      numberOfTerraces: schema.area?.numberOfTerrace,
      view: ifDefinedOtherwiseUndefined(
        schema.fitting?.view,
        () =>
          schema.fitting?.view
            ?.map((view) =>
              switchCaseMap(
                view,
                reverseItems(this.maps.view) as [View, ViewOptions][]
              )
            )
            .filter((value) => value != null) as ViewOptions[]
      ),
      parkingSpace: ifDefinedOtherwiseUndefined(
        schema.fitting?.parkingSpace,
        () => {
          const parkingSpace = schema.fitting?.parkingSpace;
          return Array.isArray(parkingSpace)
            ? (parkingSpace.map((parkingSpace) =>
                switchCaseMap(
                  parkingSpace,
                  reverseItems(this.maps.parkingSpace) as [
                    ParkingSpace,
                    ParkingSpaceOptions
                  ][]
                )
              ) as ParkingSpaceOptions[])
            : [];
        }
      ),
      numberOfParkingSpaces: schema.fitting?.parkingSpaceOutsideNumber,
      numberOfBasementGarageSpaces:
        schema.fitting?.parkingSpaceBasementGarageNumber,
      numberOfCarportSpaces: schema.fitting?.parkingSpaceCarportNumber,
      numberOfDuplexSpaces: schema.fitting?.parkingSpaceDuplexNumber,
      numberOfGarageSpaces: schema.fitting?.parkingSpaceGarageNumber,
      numberOfParkingDeckSpaces: schema.fitting?.parkingSpaceParkingDeckNumber,
      handicappedAccessible: ifDefinedOtherwiseUndefined(
        schema.fitting?.accessibility,
        () =>
          schema.fitting?.accessibility?.includes(
            Accessibility.HANDICAPPED_ACCESSIBLE
          )
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      swimmingPool: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.SWIMMING_POOL)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      winterGarden: ifDefinedOtherwiseUndefined(
        schema.fitting?.facilities,
        () =>
          schema.fitting?.facilities?.includes(Facilities.WINTER_GARDEN)
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      shutter: ifDefinedOtherwiseUndefined(schema.fitting?.facilities, () =>
        schema.fitting?.facilities?.includes(Facilities.SHUTTER)
          ? PolarOptions.Yes
          : PolarOptions.No
      ),
      isOwner: ifDefinedOtherwiseUndefined(
        schema.meta?.IREENUser?.IsOwner,
        () =>
          schema.meta?.IREENUser?.IsOwner ? PolarOptions.Yes : PolarOptions.No
      ),
      isRented: ifDefinedOtherwiseUndefined(schema.object?.IsRented, () =>
        schema.object?.IsRented ? PolarOptions.Yes : PolarOptions.No
      ),
      rentPrice: schema.priceInformation?.IREENUser?.CurrentMainRent,
      rentDate: schema.priceInformation?.IREENUser?.CurrentRentContractStart,
      fixedTermLease: ifDefinedOtherwiseUndefined(
        schema.priceInformation?.IREENUser?.leaseholdTerm,
        () =>
          schema.priceInformation?.IREENUser?.leaseholdTerm
            ? PolarOptions.Yes
            : PolarOptions.No
      ),
      fixedTermLeaseDate: schema.priceInformation?.IREENUser?.leaseholdTermEnd,
      transactionPriceHistory:
        schema.priceInformation?.IREENUser?.TransactionPriceHistory
    };
  }

  static calculateNetCosts(cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = cost / (1 + tax / 100);

    return Number(value.toFixed(2));
  }

  static calculateTaxCosts(isGross: boolean, cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = isGross ? cost * (1 - 100 / (100 + tax)) : (cost * tax) / 100;

    return Number(value.toFixed(2));
  }

  static calculateGrossCosts(cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = cost + tax;

    return Number(value.toFixed(2));
  }

  static calculateTaxRate(cost?: number, tax?: number) {
    if (!cost || !tax) {
      return undefined;
    }
    const value = (tax / cost) * 100;

    return Number(value.toFixed(0));
  }

  get refinedData() {
    return RefinedEvaluation.mapToSchema(this.features);
  }

  get getFeature() {
    return <K extends keyof IRefinedEvaluation = keyof IRefinedEvaluation>(
      name: K
    ) => this.features[name];
  }

  get totalFeatures() {
    return Object.keys(this.features).reduce((acc, key) => {
      let shouldIncrement = true;

      if (key in RefinedEvaluation.dependantFeatureKeys) {
        const dependantKeys =
          RefinedEvaluation.dependantFeatureKeys[
            key as keyof IRefinedEvaluation
          ];
        if (dependantKeys) {
          if (dependantKeys[1] !== undefined) {
            shouldIncrement =
              this.getFeature(dependantKeys[0]) === dependantKeys[1];
          } else {
            shouldIncrement = this.getFeature(dependantKeys[0]) !== undefined;
          }
        }
      }

      if (shouldIncrement) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  }

  get countFeatures() {
    return Object.keys(this.features).reduce<number>((acc, key) => {
      const value = this.features[key as keyof IRefinedEvaluation];

      if (value !== undefined) {
        return acc + 1;
      } else {
        return acc;
      }
    }, 0);
  }

  @Mutation
  setRefinedData(refinedEvaluation: EditEvaluationRequestSchema) {
    Object.assign(
      this.features,
      RefinedEvaluation.mapToLocalState(refinedEvaluation)
    );
  }

  @Debounce(1000)
  @Mutation
  setFeature<K extends keyof IRefinedEvaluation = keyof IRefinedEvaluation>({
    name,
    value
  }: {
    name: K;
    value: IRefinedEvaluation[K];
  }) {
    this.features[name] = value;
  }

  @Mutation
  setShowForm(showForm: boolean) {
    this.showForm = showForm;
  }
}
