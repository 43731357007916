
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import { Store } from '~/decorators';
import BargainStore from '~/store/bargains';
@Component
export default class Footer extends Vue {
  @Store() bargainsStore!: BargainStore;

  get list(): [any] {
    return this.bargainsStore?.getList || [];
  }

  dashboard = false;
  socialMedia = [
    {
      title: 'twitter',
      to: '/',
      icon: require('~/assets/img/footer/twitter.svg')
    },
    {
      title: 'faceBook',
      to: '/',
      icon: require('~/assets/img/footer/facebook.svg')
    },
    {
      title: 'linkedin',
      to: '/',
      icon: require('~/assets/img/footer/linkedin.svg')
    },
    {
      title: 'youtube',
      to: '/',
      icon: require('~/assets/img/footer/youtube.svg')
    }
  ];

  getInTouch = [
    { text: 'IREEN GmbH' },
    { text: 'Joanelligasse 10/11' },
    { text: '1060 Wien' }
  ];

  get currentYear() {
    return new Date().getFullYear();
  }

  get ressources() {
    return [
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.wissen.title'),
        to: this.$i18n.t('navbar.wissen.link')
      },
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.aboutUs.title'),
        to: this.$i18n.t('navbar.aboutUs.link')
      },
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.contact.title'),
        to: this.$i18n.t('navbar.contact.link')
      },
      {
        icon: 'mdi-chart-bar',
        title: this.$i18n.t('navbar.jobs.title'),
        to: this.$i18n.t('navbar.jobs.link')
      }
    ];
  }

  mounted() {
    this.dashboardCheker();
  }

  @Watch('$route')
  onRouteChange() {
    this.dashboardCheker();
  }

  dashboardCheker() {
    if (
      this.$route.path.includes('dashboard/objekt-radar') &&
      this.list.length === 0
    ) {
      this.dashboard = false;
    } else if (
      this.$route.path.includes('dashboard') &&
      this.$route.hash !== '#new_evaluation'
    ) {
      this.dashboard = true;
    } else {
      this.dashboard = false;
    }
  }

  get lang() {
    return this.$store.state.i18n.locale || '';
  }

  backStep() {
    this.$store.commit('propertyTypeSearch/resetStep');
  }
}
