import { MutationTree } from 'vuex';

export const state = () => ({
  auth: true
});
export type IsLogginState = ReturnType<typeof state>;

export const mutations: MutationTree<IsLogginState> = {
  SET_AUTH: (state, auth: boolean) => (state.auth = auth)
};
