
import { Component, Prop, Vue } from 'nuxt-property-decorator';
@Component
export default class LeavingExpose extends Vue {
  @Prop() show!: boolean;
  close() {
    this.$emit('closeLeaveingExpose');
  }

  get lang() {
    return this.$store.state.i18n.locale || '';
  }

  changeRoute() {
    this.close();
    this._goToDashboardRoute();
  }

  private _goToDashboardRoute() {
    this.$router.push(`/${this.lang}/dashboard`);
  }
}
