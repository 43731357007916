import type { MutationTree } from 'vuex';

export const state = () => ({
  firstName: '',
  lastName: '',
  email: '',
  thankYou: false
});
export type NewsLetter = ReturnType<typeof state>;

export const mutations: MutationTree<NewsLetter> = {
  SET_THANKYOU: (state: any, thankYou: boolean) => (state.thankYou = thankYou),
  SET_FIRSTNAME: (state: any, firstName: string) =>
    (state.firstName = firstName),
  SET_LASTNAME: (state: any, lastName: string) => (state.lastName = lastName),
  SET_EMAIL: (state: any, email: string) => (state.email = email)
};
