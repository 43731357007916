import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  BargainsFilter,
  BuyRent,
  ObjectType,
  Subscription,
  login,
  AlleAnzeigen,
  Sort,
  conditionItems,
  Details,
  ObjectTypeOptionApartment,
  ObjectTypeOptionHouse
} from '~/entities/bargains';
import type { pagination } from '~/entities/bargains';
import { data } from '~/schema/bargains';
@Module({
  name: 'bargains',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class Bargains extends VuexModule {
  details: Details = {};

  subscription: Subscription = {
    login: login.login,
    selected: false,
    id: 'price_1M3zWqHUXEYAV7fAHbWqv2k8',
    productId: 'prod_MnaiytUd1lxXP6',
    interval: 'month',
    intervalCount: 3,
    price: '360'
  };

  filter: BargainsFilter = {
    buyRent: BuyRent.Buy,
    objectType: ObjectType.All,
    condition: conditionItems.all,
    alleAnzeigen: AlleAnzeigen.All,
    objectTypeOption: ObjectTypeOptionHouse.All,
    sort: Sort.BesteRendite,
    region: undefined,
    fromSize: { from: undefined, to: undefined },
    fromRoom: { from: undefined, to: undefined },
    price: { from: undefined, to: undefined },
    outdoorArea: false,
    rendite: undefined,
    exactAddressKnown: false,
    faiv: false,
    total: 0,
    count: 0,
    page: 1,
    size: 9,
    loading: false
  };

  list?: Array<data> = [];

  @Mutation
  setSubscription(val: any) {
    this.subscription.id = val.id;
    this.subscription.productId = val.productId;
    this.subscription.interval = val.interval;
    this.subscription.intervalCount = val.intervalCount;
    this.subscription.price = val.price;
  }

  @Mutation
  setBargainsDetailes(data: any) {
    this.details = data;
    const item = this.list?.find((item) => item._id === data._id);
    this.details.tags = item.tags;
  }

  @Mutation
  setAlleAnzeigen(val: AlleAnzeigen) {
    this.filter.alleAnzeigen = val;
  }

  @Mutation
  setObjectTypeOptopn(val: ObjectTypeOptionHouse | ObjectTypeOptionApartment) {
    this.filter.objectTypeOption = val;
  }

  @Mutation
  setSort(val: Sort) {
    this.filter.sort = val;
  }

  @Mutation
  setSubscriptionSelect(val: boolean) {
    this.subscription.selected = val;
  }

  @Mutation
  addFave(id: string) {
    const item = this.list?.find((item) => item._id === id);
    item?.tags?.push('favorite');
  }

  @Mutation
  removeFave(id: string) {
    const item = this.list?.find((item) => item._id === id);
    item?.tags = [];
    if (this.details._id === item._id) {
      this.details?.tags = [];
    }
  }

  @Mutation
  setFaiv(val: boolean) {
    this.filter.faiv = val;
  }

  @Mutation
  setRendite(val: number | undefined) {
    this.filter.rendite = val;
  }

  @Mutation
  setLoginType(val: login) {
    this.subscription.login = val;
  }

  @Mutation
  setList(data: data[] | undefined) {
    this.list = data;
  }

  @Mutation
  setPage(val: number) {
    this.filter.page = val;
  }

  @Mutation
  setCondition(val: conditionItems) {
    this.filter.condition = val;
  }

  @Mutation
  setLoading(val: boolean) {
    this.filter.loading = val;
  }

  get getList() {
    return this.list;
  }

  get filtersData() {
    return this.filter;
  }

  get getLoading() {
    return this.filter.loading;
  }

  @Mutation
  setPagination(data: pagination) {
    this.filter.count = data.count;
    this.filter.total = data.total;
    this.filter.page = data.page;
  }

  @Mutation
  setRegion(region: BargainsFilter['region']) {
    this.filter.region = region;
  }

  @Mutation
  setExactAddressKnown(exactAddressKnown: boolean) {
    this.filter.exactAddressKnown = exactAddressKnown;
  }

  @Mutation
  setOutdoorArea(outdoorArea: boolean) {
    this.filter.outdoorArea = outdoorArea;
  }

  @Mutation
  setFromRoomFrom(from: number | undefined) {
    this.filter.fromRoom.from = from;
  }

  @Mutation
  setFromRoomTo(to: number | undefined) {
    this.filter.fromRoom.to = to;
  }

  @Mutation
  setFromSizeFrom(from: number) {
    this.filter.fromSize.from = from;
  }

  @Mutation
  setPriceTo(to: number | undefined) {
    this.filter.price.to = to;
  }

  @Mutation
  setPriceFrom(from: number) {
    this.filter.price.from = from;
  }

  @Mutation
  setFromSizeTo(to: number) {
    this.filter.fromSize.to = to;
  }

  @Mutation
  setBuyRent(buyRent: BuyRent) {
    this.filter.buyRent = buyRent;
  }

  @Mutation
  setObjectType(objectType: ObjectType) {
    this.filter.objectType = objectType;
    this.filter.objectTypeOption = ObjectTypeOptionApartment.All;
  }
}
